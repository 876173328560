import { State } from '@/views/login/login.types';

export const CAROUSEL_TITLES: string[] = [ 'revenueManagers', 'complexAlgorithms', 'knowHow', 'functionYieldOn' ];

export const state: State = {
  cookieAccepted: false,
  loginForm: {
    errors: '',
  },
  carousel: {
    currentSlideIndex: 0,
    titles: CAROUSEL_TITLES,
  },
};
