import { Module }    from 'vuex';
import { State }     from '@/views/login/login.types';
import { RootState } from '@/store/store.types';
import actions       from '@/views/login/store/login.actions';
import { state }     from '@/views/login/store/login.state';
import { mutations } from '@/views/login/store/login.mutations';

const store: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default store;
