import { ActionContext, ActionTree } from 'vuex';
import i18n                          from '@/i18n';
import providers                     from '@/providers';
import { RootState }                 from '@/store/store.types';
import { LoginFormFields, State }    from '@/views/login/login.types';
import { loginUser }                 from '@/views/login/login.service';
import { Notification }              from '@/components/notification/notification';
import { GLOBAL }                    from '@/helpers/variables';
import { name as vLogin }            from '@/views/login/login.route';
import { name as vDashboard }        from '@/views/dashboard/dashboard.route';

const actions: ActionTree<State, RootState> = {
  async loginUser(
    context: ActionContext<State, RootState>,
    form: LoginFormFields
  ): Promise<void> {
    try {
      await loginUser(form);
      await providers.router?.push({ name: vDashboard });
      await location.reload();
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t(`${vLogin}.form.success.loggedIn`)
      );
    } catch (e) {
      if (e.status === 401) {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${vLogin}.form.errors.BAD_CREDENTIALS`)
        );
      }
      throw e.data;
    }
  },
  async checkCookiePolicy(
    context: ActionContext<State, RootState>
  ): Promise<void> {
    try {
      const cookies: { [key: string]: string } = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );

      if (Object.prototype.hasOwnProperty.call(cookies, 'cookiePolicy')) {
        context.commit('setCookiePolicy', true);
      }
    } catch (e) {
      throw e.data;
    }
  },
};

export default actions;
