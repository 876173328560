import axios, { AxiosPromise } from 'axios';
import { getUrl } from '@/service/service.helpers';
import { LoginFormFields } from '@/views/login/login.types';

export function loginUser({ password, login }: LoginFormFields): AxiosPromise {
  return axios.post(getUrl('/users/login'), {
    password,
    email: login,
  });
}
