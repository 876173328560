import { State } from '@/views/login/login.types';

export const mutations = {
  setCurrentSlideIndex(state: State, payload: number): void {
    state.carousel.currentSlideIndex = payload;
  },
  setLoginErrors(state: State, errors?: any[]): void {
    state.loginForm.errors = errors && errors.length ? errors[ 0 ].reason : '';
  },
  setCookiePolicy(state: State, payload: boolean): void {
    state.cookieAccepted = payload;
    document.cookie = 'cookiePolicy=';
  },
};
